.homepage {
    position: relative;
    height: 100%;
    background: #ffffff;
}

.homepage img {
    width: 100%;
}

.homepage--background {
    width: 100%;
    height: 100%;
}

.homepage--welcome {
    width: 340px;
    position: relative;
    top: 329px;
    left: 132px;
}

.homepage--welcome--title {
    font-size: 34px;
}

.homepage--welcome--blurb {
    font-size: 14px;
}

.homepage--welcome--blurb > a {
    color: inherit;
    text-decoration: inherit;
}

.homepage--logo--group {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.homepage--logo {
    width: 150px;
    height: 75px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.2s ease-in-out;
    padding-bottom: 20px;
}

.homepage--logo:not(.homepage--logo--editing):hover {
    transform: scale(1.1);
}

.homepage--logo img {
    width: auto;
    max-height: 42px;
}

.homepage--logo--disable {
    opacity: 0.4;
    cursor: url('../../../../images/noaccess.svg'), auto;
}

.homepage--logo--editing {
    animation: wobble 0.2s infinite;
    -webkit-animation: wobble 0.2s infinite;
    -webkit-transform: rotate(-2deg);
}

@keyframes wobble {
    0% {
        transform: rotate(-2deg);
    }
    50% {
        transform: rotate(2deg);
    }
}