.button--group {
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: center;
}

.button--group.col {
    height: 100%;
    flex-flow: column wrap;
    align-content: center;
    justify-content: center;
    margin: 10px 15px;
}

.button--group.row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
}

.button--card {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: 300ms ease-out;
    height: 20px;
    width: 100px;
    font-size: 0.75em;
}

.button--card.active {
    transform: scale(1.1);
    box-shadow: 0 0 5px rgba(25, 25, 25, 0.25);
}