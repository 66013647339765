.btn--select--cat:hover {
    transform: scale(1.1);
}

.btn--select--cat.opts {
    min-width: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.img--details {
    width: 65px;
    height: 65px;
    padding-right: 75px;
}

.details {
    width: 90%;
    display: flex;
    height: 100px;
    border-bottom: 1px solid rgba(70, 71, 73, 0.2);
    align-items: center;
    margin-bottom: 30px;
}

.details--items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 50px;

}

.details--items > span:first-child {
    padding-bottom: 5px;
}

.item--name {
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-size: 0.9em;
}

.selected--content, .modal--file--upload {
    width: 50%;
    height: 200px;
}

.modal--file--upload, .modal--paste--input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}

.modal--file--upload.master--campaign, .modal--paste--input {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.paste--master--id {
    height: 300px;
    width: 100%;
}

.selected--element {
    height: 20px;
    display: flex;
    align-items: center;
}

.selected--element > span {
    padding-left: 10px;
}

.selected--element > svg {
    cursor: pointer;
}

.selected--element > svg:hover {
    fill: #BA6A5D;
}

.channel--item {
    pointer-events: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: url('../../../../../images/noaccess.svg'), auto;
    opacity: 0.2;
}

.channel--item.avail {
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
}

.channel--item.avail.clicked > span {
    color: #BA6A5D;
    transform: scale(1.3);
}

.channel--item.avail.clicked.primary > span {
    color: #216D4B;
}

.channel--image {
    height: 50px;
    width: 50px;
    padding-bottom: 10px;
}

.title--selection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title--selection > svg {
    cursor: pointer;
}

.title--results {
    height: 40px;
    width: 1020px;
    margin: 10px;
    position: relative;
    flex-shrink: 0;
}

.title--results > a {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px;
    left: 10px;
}

.grid--results {
    width: 1200px;
    margin: 40px auto;
}

.tile--reuslts {
    display: flex;
    flex-direction: column;
    width: 1200px;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.summary {
    width: 1020px;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
}

.summary.title {
    width: 1200px;
    height: 50px;
    align-items: center;
    font-size: 1.25em;
    justify-content: center;
}

.summary--tiles--value, .labels {
    font-family: 'Archivo', sans-serif;
    fill: #707173
}

.summary--tiles--value {
    font-size: 25px;
}

.summary--tiles--label {
    text-align: center;
    font-size: 15px;
    fill: #BA6A5D;
}

.chart--container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    box-shadow: 0 0 5px rgba(25, 25, 25, 0.25);
    margin-top: 30px;
}

.chart--container.integrated > .top--bar {
    width: 100%;
    display: flex;
    justify-content: space-around;
    height: 50px;
}

.chart--container.integrated > .top--bar > .radio--group {
    display: flex;
    align-items: center;
}

#uplift--chart .bar {
    cursor: pointer;
}

.integrated--info--topbar {
    width: 50%;
    display: flex;
    align-items: center;
}

.integrated--info--topbar.type {
    justify-content: flex-start;
}

.integrated--info--topbar.back {
    justify-content: flex-end;
}

.integrated--info {
    height: 460px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.integrated--info--widget {
    width: 20%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.integrated--info--widget > div {
    margin: 5px;
}

.integrated--info--widget--result {
    display: flex;
}

.integrated--info--widget--result span {
    margin: 0 10px;
    font-family: 'Archivo', sans-serif;
}

.integrated--info--widget--result--value {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.axis path{
    stroke: #D5DCE4;
}

.axis line{
    stroke: #D5DCE4;
}

.axis text{
    fill: #707173;
    font-family: 'Archivo', sans-serif;
}

.toolTip {
    color: #707173;
    position: absolute;
    display: none;
    min-width: 200px;
    height: auto;
    border: #707173 2px solid;
    background: white;
    padding: 14px;
    text-align: center;
    border-radius: 3px;
}

.metric--selections {
    width: 200px;
}

li {
    padding-bottom: 10px;
}

.tab--content {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 30px auto;
    height: 500px;
    overflow: auto;
}

.bullet--title {
    font-size: 20px;
}

.tab--content li {
    font-size: 15px;
}

.save--filters{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.save--filters > div {
    margin-bottom: 50px;
}

.open--filters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 300px;
}

.open--filters > .radio--group {
    width: 33%;
    margin-right: 20px;
    overflow-y: auto;
    overflow-x: hidden;
}