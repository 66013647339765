.error--shake > div {
    animation: shake 0.82s cubic-bezier(0.23, 1, 0.32, 1) both;
    transform: translate(0px, 0px);
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-4px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(5px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-7px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(7px, 0, 0);
    }
}