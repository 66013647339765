.app--bar {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #212529;
    color: #ffffff;
    -webkit-app-region: drag;
    flex: 0 0 auto;
    font-family: VennSemiEx, Helvetica, sans-serif;
    cursor: default;
}

.app--bar.green {
    background: -webkit-linear-gradient(#6ce05b, #3bc141);
}

.app-left {
    padding-left: 10px;
}

.app-icons {
    height: 24px;
    width: 25px;
}

.footer {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    background-color: #51586a;
    color: #ffffff;
    justify-content: space-between;
    -webkit-app-region: drag;
    flex: 0 0 auto;
}

.window--icon,
.menu--right {
    display: flex;
    flex-direction: row;
    height: 100%;
    -webkit-app-region: no-drag;
    cursor: pointer;
}

.menu--left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu--icon {
    -webkit-app-region: no-drag;
    cursor: pointer;
    height: 40px;
    width: 40px;
    transition: 200ms ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu--icon:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.menu--module--name {
    -webkit-app-region: no-drag;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 18px;
    line-height: 1.2;
}

.menu--auto--save {
    margin-left: 10px;
    opacity: 0.5;
    transition: opacity 0.4s linear;
}

.icon--set {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    transition: 200ms ease-out;
}

.icon--set:hover {
    background-color: #8b98b6;
}

.underline_link {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
}

.underline_link:after {
    transition: all 0.3s ease-in-out;
    position: absolute;
    content: ' ';
    bottom: 0;
    left: 0;
    right: 100%;
    display: block;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.underline_link:hover:after {
    right: 0;
    border-bottom-color: rgba(255, 255, 255, 0.5);
}

.nectar-brand {
    background-color: #EB0050;
    height: 44px;
    width: 44px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nectar-brand__logo {
    width: 34px;
}
