@font-face {
    font-family: 'Mary-Ann';
    src: url('../../../fonts/mary_ann_web-regular.ttf');
    font-weight: normal
}

@font-face {
    font-family: 'Mary-Ann';
    src: url('../../../fonts/mary_ann_web-medium.ttf');
    font-weight: 300
}

@font-face {
    font-family: 'Mary-Ann';
    src: url('../../../fonts/mary_ann_web-bold.ttf');
    font-weight: bold
}

@font-face {
    font-family: Venn;
    src: url('../../../fonts/Venn_W_Rg.woff2');
    font-weight: normal;
  }

  @font-face {
    font-family: Venn;
    src: url('../../../fonts/Venn_W_XBd.woff2');
    font-weight: bold;
  }

  @font-face {
    font-family: VennSemiEx;
    src: url('../../../fonts/VennSemiEx_W_Rg.woff2');
    font-weight: normal;
  }

  @font-face {
    font-family: VennSemiEx;
    src: url('../../../fonts/VennSemiEx_W_XBd.woff2');
    font-weight: bold;
  }

html, body, #content, #content > div {
    height: 100%;
    overflow: hidden;
}

* {
    user-select: none;
}

body {
    margin: 0;
    font-family: 'Venn', Helvetica, sans-serif;
    font-weight: 400;
    color: #707173;
    background-color: #E5E5E5;
}

input {
    font-family: 'Venn', Helvetica, sans-serif;
    font-weight: 400;
    color: #707173;
}

.no--select {
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -o-user-select: none !important;
    user-select: none !important;
}

.window {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.window--content {
    position: relative;
    transition: all 0.3s ease-in-out;
    transition-delay: 0.2s;
    background-color: #E5E5E5;
    background-image: url(../../../images/circles.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.view--content {
    display: flex;
    height: 100%;
    min-width: 1240px;
    min-height: 900px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    overflow: auto;
}

.bottom--logo {
    position: absolute;
    bottom: 30px;
    right: 30px;
}

.sidebar--overlay {
    z-index: 2 !important;
}

.btn {
    animation-delay: 3s;
}
