.loader_spin,
.loader_spin:before,
.loader_spin:after {
    display: inline-block;
    box-sizing: border-box;
    position: relative;
}

.loader_spin svg {
    display: block;
    margin: 0;
    padding: 0;
}

.loader_spin .spinner {
    width: 66px;
    height: 66px;
    -webkit-animation: contanim 2s linear infinite;
    animation: contanim 2s linear infinite;
}

.loader_spin svg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    stroke: #cccccc;
    stroke-dashoffset: 0;
    -webkit-animation: strokeanim 3s calc(0.2s) ease infinite;
    animation: strokeanim 3s calc(0.2s) ease infinite;
    -webkit-transform-origin: center center;
    transform-origin: center center;
}

.loader_spin.complete svg {
    animation-fill-mode: forwards;
    -webkit-animation: strokecomplete 3s ease;
    animation: strokecomplete 3s ease;
}

@-webkit-keyframes strokecomplete {
    0% {
        stroke-dasharray: 1, 300;
    }
    100% {
        stroke-dasharray: 300, 300;
    }
}

@keyframes strokecomplete {
    0% {
        stroke-dasharray: 1, 300;
    }
    100% {
        stroke-dasharray: 300, 300;
    }
}

@-webkit-keyframes strokeanim {
    0% {
        stroke-dasharray: 1, 300;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 120, 300;
        stroke-dashoffset: -175.6449737548828 / 3;
    }
    100% {
        stroke-dasharray: 120, 300;
        stroke-dashoffset: -175.6449737548828;
    }
}

@keyframes strokeanim {
    0% {
        stroke-dasharray: 1, 300;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 120, 300;
        stroke-dashoffset: -175.6449737548828 / 3;
    }
    100% {
        stroke-dasharray: 120, 300;
        stroke-dashoffset: -175.6449737548828;
    }
}
@-webkit-keyframes contanim {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes contanim {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
