.search {
    width: 50%;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
}

.search--input {
    background-color: transparent;
    margin: 0 auto;
    display: block;
}

.search--input input {
    padding-top: 0;
    padding-bottom: 0;
    color: inherit;
    width: 100%;
    height: 100%;
    padding-left: 40px;
    border: #E0E0E0 solid 1px;
    border-radius: 10px;
    display: inline-block;
    box-sizing: border-box;
}

.search--input input:focus {
    outline: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}


.search .search--icon {
    color: rgba(0, 0, 0, 0.26);
    padding-left: 5px;
    display: inline;
    position: absolute;
}

.search--item {
    padding: 2px 40px;
    cursor: default;
    color: #707173
}

.search--item:hover {
    color: white;
    background: #8B98B6;
}