.app-bar--sidebar {
    width: 100%;
    height: 40px;
    background: -webkit-linear-gradient(RGBA(48, 57, 72, 0.9),RGBA(48, 57, 72, 1.00));
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu--item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 40px;
    width: calc(100% - 10px);
    border-bottom: 2px #eee solid;
    padding-left: 10px;
    transition: all 0.2s ease-in-out;
}

.menu--item:hover {
    cursor: pointer;
    background-color: #F4F4F4;
}

.menu--text {
    width: calc(100% - 35px);
}